<template>
    <div class="ategories_page">
        <img class="logo" src="@/assets/img_pc/logo.png" alt="">
        <img class="back click_cursor" @click="$router.go(-1)" src="@/assets/img_pc/back.png" alt="">
        <div class="container flex_wrap flex_justify_between">
            <a :href="item.href" class="item click_cursor" v-for="(item, index) of productsData" :key="index" target="_blank">
                <img class="absolute_all_0 w_h_100" :src="item.ategoriesImg" alt="">
                <div class="detail flex_col flex_justify_end absolute_all_0 w_h_100">
                    <div class="name">{{item.detailCn}}</div>
                    <h4>coril</h4>
                    <div class="color_wrap flex_align_center">
                        <img :src="colorTags[colorItem]" alt="" v-for="(colorItem, colorIndex) of item.colors"
                            :key="colorIndex">
                        <span class="HurmeGeometricSans3_SemiBold">￥{{item.price}}</span>
                    </div>
                </div>
            </a>
        </div>
        <foot></foot>
        <to-top></to-top>
    </div>
</template>

<script>
    import white from '@/assets/img_pc/white_tag.png'
    import black from '@/assets/img_pc/black_tag.png'
    import wathet from '@/assets/img_pc/wathet_tag.png'
    import grey from '@/assets/img_pc/grey_tag.png'

    import foot from "@/components/pc/foot.vue"
    import toTop from "@/components/pc/to-top.vue"

    import { mapState } from 'vuex'

    export default {
        name: 'ategories',
        data() {
            return {
                colorTags: {
                    'white': white,
                    'black': black,
                    'wathet': wathet,
                    'grey': grey
                }
            }
        },
        computed: {
            ...mapState([
                'productsData'
            ])
        },
        components: {
            foot,
            toTop
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/style/mixin.scss";

    .ategories_page {
        min-height: 100vh;
        background-color: #303030;
        padding-top: formatPX_1920(30px);

        .logo {
            width: formatPX_1920(124px);
            height: formatPX_1920(38px);
            margin: 0 auto;
        }

        .back {
            width: formatPX_1920(90px);
            height: formatPX_1920(58px);
            margin: formatPX_1920(60px) 0 0 formatPX_1920(300px);
        }

        .container {
            flex-direction: row-reverse;
            padding: formatPX_1920(120px) formatPX_1920(380px) formatPX_1920(90px) formatPX_1920(300px);

            .item {
                color: #fff;
                position: relative;
                width: formatPX_1920(580px);
                height: formatPX_1920(414px);
                margin-top: formatPX_1920(60px);

                &:nth-child(2n) {
                    margin-top: 0;
                }

                &:hover {
                    .detail {
                        display: flex;
                    }
                }

                .detail {
                    display: none;
                    background-color: rgba(0, 0, 0, 0.3);
                    padding: 0 formatPX_1920(30px) formatPX_1920(30px);

                    .name {
                        line-height: formatPX_1920(75px);
                        font-size: formatPX_1920(20px);
                        border-bottom: 1px solid rgba(255, 255, 255, 0.45);
                    }

                    h4 {
                        line-height: formatPX_1920(22px);
                        font-size: formatPX_1920(20px);
                        margin: formatPX_1920(14px) 0 formatPX_1920(18px);
                    }

                    .color_wrap {
                        img {
                            width: formatPX_1920(23px);
                            height: formatPX_1920(23px);
                            margin-left: formatPX_1920(16px);

                            &:first-child {
                                margin-left: 0;
                            }
                        }

                        span {
                            line-height: formatPX_1920(27px);
                            font-size: formatPX_1920(20px);
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
</style>